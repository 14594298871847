@import "node_modules/@digitallyhappy/backstrap/src/scss/_backstrap_colors";

$primary:       $yellow !default; // <--- For eg. This will make all buttons and texts black instead of purple
$secondary:     $gray-300 !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$dark:          #1a213c !default;

$hover-color: rgba(105, 171, 239, 0.12);
$border-color: rgba(0, 40, 100, 0.12);
$muted-bg-color: rgba(0, 0, 0, 0.02);
$sidebar-bg: $dark;
$font-size-xs: 0.7rem;

$theme-colors: () !default;
$theme-colors: map-merge(
        (
            "primary":    $primary,
            "default":    $secondary,
            "secondary":  $secondary,
            "success":    $success,
            "info":       $info,
            "notice":     $info,
            "warning":    $warning,
            "danger":     $danger,
            "error":      $danger,
            "light":      $light,
            "dark":       $dark
        ),
        $theme-colors
);

@import "node_modules/@digitallyhappy/backstrap/src/scss/_backstrap_miscellaneous";

@import "node_modules/@coreui/coreui/scss/coreui";
@import "node_modules/@digitallyhappy/backstrap/src/scss/_custom";
@import "node_modules/animate.css/source/_base";
@import "node_modules/noty/src/noty";
@import "form-upload";
@import "document-upload";
.btn-link {
    color: $dark;
}

.form-control {
    &:focus {
        border-color: $gray-300;
        box-shadow: none;
    }
}

.sidebar {
    .nav-link {
        &.active {
            color: $primary;

            &:hover {
                color: $white;
            }
        }
    }
}

.status-approved {
    background: $success;
    border-radius: 15px;
    text-align: center;
    color: white;
    display: inline-block;
    padding: 0 15px;
}

.status-hidden {
    background: $danger;
    border-radius: 15px;
    text-align: center;
    color: white;
    display: inline-block;
    padding: 0 15px;
}

.status-draft {
    background: $secondary;
    border-radius: 15px;
    text-align: center;
    color: $dark;
    display: inline-block;
    padding: 0 15px;
}

.status-pending {
    background: $yellow;
    border-radius: 15px;
    text-align: center;
    color: white;
    display: inline-block;
    padding: 0 15px;
}

.status-reject, .status-cancelled {
    background: $danger;
    border-radius: 15px;
    text-align: center;
    color: white;
    display: inline-block;
    padding: 0 15px;
}

.w-nowrap {
    white-space: nowrap;
}

.existing-file {
    overflow: hidden;
}
